import { Model } from 'myassays-global';

export default class FillSettings extends Model {
    static defaults = {
        groupNumber: 0,
        autoIncrement: true,
        replicates: 1,
        replicateDirection: "row",
        fillDirection: "row",
        rectangularFill: false,
        displaySettingsOnNextFill: true,
    };
}
