import { Model } from 'myassays-global';

export default class Cell extends Model {
    static defaults = {
        id: 0,
        number: 0,
        sampleTypeId: 0,
        selected: false,
        flagged: false,
    };
}
