
export default class HistoryManager extends EventTarget {
    constructor() {
        super();

        this.length = 0;
        this.index = 0;
        this.history = [];
        this.currentTransaction = null;
    }

    startTransaction() {
        this.currentTransaction = [];
    }

    addChange(before, after) {
        if (this.currentTransaction) {
            this.currentTransaction.push({
                before: before,
                after: after
            });
        }
    }

    endTransaction() {
        if (this.currentTransaction) {
            this.history = this.history.slice(0, this.index).concat([this.currentTransaction]);
            this.length = this.index = this.history.length;
            this.dispatchChangeEvent();
            this.currentTransaction = null;
        }
    }

    dispatchChangeEvent() {
        this.dispatchEvent(new Event(HistoryManager.EVENT_HISTORY_CHANGE));
    }

    undo() {
        const index = this.index - 1;
        if (index >= 0) {
            this.index = index;
            // delay change event to allow undo to happen
            setTimeout(() => {
                this.dispatchChangeEvent();
            }, 1);
            return this.history[index].slice().reverse();
        } else {
            return null;
        }
    }

    redo() {
        if (this.index < this.length) {
            this.index++;
            // delay change event to allow undo to happen
            setTimeout(() => {
                this.dispatchChangeEvent();
            }, 1);
            return this.history[this.index-1];
        } else {
            return null;
        }
    }

    canUndo() {
        return this.index > 0;
    }

    canRedo() {
        return this.index < this.length;
    }

    static EVENT_HISTORY_CHANGE = "history_change";
}
