import {Utils} from 'myassays-global';

export default class FillSettingsView extends EventTarget {
    constructor(rootElement, fillSettingsModel, options) {
        super();

        this.model = fillSettingsModel;
        this.options = options || {};
        this.saveState = this.model.attributes;

        this.root = rootElement;
        this.root.innerHTML = FillSettingsView.template(`${this.options.idPrefix}-fill-settings-`, {
            modal: !!this.options.modal,
            cellsLength: this.options.cellsLength,
        });
        this.update();
        const popupElem = this.root.querySelector('.--mle-fillSettingsView');
        popupElem.style.top = (this.root.offsetHeight/2 - popupElem.offsetHeight/2) + 'px';
        if (this.options.left) {
            popupElem.style.left = Math.min(this.options.left, this.root.offsetWidth - popupElem.offsetWidth) + 'px';
        } else {
            popupElem.style.left = (this.root.offsetWidth/2 - popupElem.offsetWidth/2) + 'px';
        }
        this.model.addEventListener('change', this.update);
        this.addControlEventListeners();
    }

    close() {
        this.model.removeEventListener('change', this.update);
        this.root.innerHTML = '';
    }

    addControlEventListeners() {
        this.root.querySelectorAll('button.option').forEach(elem => elem.addEventListener('click', this.onOptionClick));
        this.root.querySelector('[name="resetGroupNumber"]').addEventListener('click', this.onResetGroupNumberClick);
        this.root.querySelector('[name="groupNumber"]').addEventListener('change', this.onGroupNumberChange);
        this.root.querySelector('[name="autoIncrement"]').addEventListener('change', this.onAutoIncrementChange);
        this.root.querySelector('[name="replicates"]').addEventListener('change', this.onReplicatesChange);
        this.root.querySelectorAll('input[name=replicateDirection]').forEach(elem => elem.addEventListener('change', this.onReplicateDirectionChange));
        this.root.querySelectorAll('input[name=fillDirection]').forEach(elem => elem.addEventListener('change', this.onFillDirectionChange));
        this.root.querySelector('[name="rectangularFill"]').addEventListener('change', this.onRectangularFillChange);
        this.root.querySelector('[name="displaySettingsOnNextFill"]').addEventListener('change', this.onDisplaySettingsOnNextFillChange);

        Utils.makeMovable(this.root.querySelector('.--mle-fillSettingsView'), this.root.querySelector('.--mle-dragHandle')).onStart(() => {
            this.options.onDragStart && this.options.onDragStart();
        });

        if (this.options.modal) {
            this.root.querySelector('.--mle-modalMask').addEventListener('click', evt => evt.preventDefault());
        }
    }

    onOptionClick = evt => {
        const index = parseInt(evt.currentTarget.getAttribute("data-index"));
        switch (index) {
            case 0:
                this.onOkClick();
                break;
            case 1:
                this.onCancelClick();
                break;
            case 2:
                this.onCloseClick();
                break;
        }
    }

    onOkClick() {
        this.close();
        if (this.options.callBack) {
            this.options.callBack(0);
        }
    }

    onCancelClick() {
        this.model.set(this.saveState);
        this.close();
        if (this.options.callBack) {
            this.options.callBack(1);
        }
    }

    onCloseClick() {
        this.close();
        if (this.options.callBack) {
            this.options.callBack(2);
        }
    }

    onResetGroupNumberClick = evt => {
        this.model.set({groupNumber: 1});
        evt.preventDefault();
    }

    onGroupNumberChange = evt => {
        const input = evt.currentTarget;
        if (input.value < 1) input.value = 1;
        if (input.value > this.options.cellsLength) input.value = this.options.cellsLength;
        this.model.set({
            groupNumber: parseInt(input.value)
        });
    }

    onAutoIncrementChange = evt => {
        this.model.set({autoIncrement: evt.currentTarget.matches(':checked')});
    }

    onReplicatesChange = evt => {
        const input = evt.currentTarget;
        if (input.value < 1) input.value = 1;
        if (input.value > this.options.cellsLength) input.value = this.options.cellsLength;
        this.model.set({
            replicates: parseInt(input.value)
        });
    }

    onReplicateDirectionChange = evt => {
        const target = evt.currentTarget;
        if (target.matches(':checked')) {
            this.model.set({replicateDirection: target.value});
        }
    }

    onFillDirectionChange = evt => {
        const target = evt.currentTarget;
        if (target.matches(':checked')) {
            this.model.set({fillDirection: target.value});
        }
    }

    onRectangularFillChange = evt => {
        this.model.set({rectangularFill: evt.currentTarget.matches(':checked')});
    }

    onDisplaySettingsOnNextFillChange = evt => {
        this.model.set({displaySettingsOnNextFill: evt.currentTarget.matches(':checked')});
    }

    updateOptions(options) {
        this.options = {
            ...this.options,
            ...options,
        };
        this.update();
    }

    update = evt => {
        const data = this.model.attributes;
        data.showGroupNumber = this.options.callBack && !this.options.isNullSampleType;
        data.showAutoIncrement = !this.options.isNullSampleType;
        data.showReplicates = data.autoIncrement && !this.options.isNullSampleType;
        data.showReplicateDirection = data.showReplicates && data.rectangularFill && data.replicates > 1;
        data.showFillDirection = true;
        if (this.options.startRowCol && this.options.endRowCol) {
            const singleRowOrColumn = this.options.startRowCol.row === this.options.endRowCol.row || this.options.startRowCol.column === this.options.endRowCol.column;
            if (singleRowOrColumn && data.rectangularFill) {
                data.showFillDirection = false;
            }
        }

        const groupNumberInput = this.root.querySelector('[name="groupNumber"]');
        const groupNumberLabel = this.root.querySelector(`[for="${groupNumberInput.id}"]`);
        groupNumberInput.value = data.groupNumber;
        groupNumberLabel.innerHTML = data.autoIncrement ? 'Group Start:' : 'Group:';
        this.root.querySelector('[name="autoIncrement"]').checked = data.autoIncrement;
        this.root.querySelector('[name="replicates"]').value = data.replicates;
        this.root.querySelectorAll('input[name=replicateDirection]').forEach(elem => elem.checked = elem.value === data.replicateDirection);
        this.root.querySelectorAll('input[name=fillDirection]').forEach(elem => elem.checked = elem.value === data.fillDirection);
        this.root.querySelector('[name="rectangularFill"]').checked = data.rectangularFill;
        this.root.querySelector('[name="displaySettingsOnNextFill"]').checked = data.displaySettingsOnNextFill;
        this.root.querySelector('.--mle-showGroupNumber').classList.toggle('--mle-hide', !data.showGroupNumber);
        this.root.querySelector('.--mle-showAutoIncrement').classList.toggle('--mle-hide', !data.showAutoIncrement);
        this.root.querySelector('.--mle-showReplicates').classList.toggle('--mle-hide', !data.showReplicates);
        this.root.querySelector('.--mle-showReplicateDirection').classList.toggle('--mle-hide', !data.showReplicateDirection);
        this.root.querySelector('.--mle-showFillDirection').classList.toggle('--mle-hide', !data.showFillDirection);
    }

    static template = (id, data) => `
${data.modal ? '<div class="--mle-modalMask"> </div>' : ''}
<div class="modal" style="display: block">
    <div class="--mle-fillSettingsView modal-dialog">
    <div class="modal-content">
        <div class="modal-header --mle-dragHandle"><h5>Fill Settings</h5></div>
        <div class="modal-body">
            <form><table>
               <tr class="--mle-showGroupNumber">
                   <td>
                       <label class="form-label" for="${id}-groupNumber">Group Start:</label>
                   </td>
                   <td>
                        <div class="input-group input-group-sm">
                            <input maxlength="4" type="number" id="${id}-groupNumber" name="groupNumber" class="form-control" min="1" max="${data.cellsLength}"/>
                            <button name="resetGroupNumber" class="btn btn-secondary">Reset</button>
                        </div>
                   </td>
               </tr>
               <tr class="--mle-showAutoIncrement">
                   <td>&nbsp;</td>
                   <td>
                        <input class="form-check-input" type="checkbox" id="${id}-autoIncrement" name="autoIncrement"/>
                        <label class="form-check-label" for="${id}-autoIncrement">Auto Increment</label>
                   </td>
               </tr>
               <tr class="--mle-showReplicates">
                   <td>
                        <label class="form-label" for="${id}-replicates">Replicates:</label>
                   </td>
                   <td>
                        <input maxlength="4" class="form-control form-control-sm" type="number" id="${id}-replicates" name="replicates" min="1" max="${data.cellsLength}"/>
                   </td>
               </tr>
               <tr class="--mle-showReplicateDirection">
                   <td>
                        <label class="form-label">Replicates Direction:</label>
                   </td>
                   <td>
                        <input class="form-check-input" type="radio" name="replicateDirection" id="${id}-replicateDirectionRow" value="row"/>
                        <label class="form-check-label" for="${id}-replicateDirectionRow">By Row</label>&nbsp;
                        <input class="form-check-input" type="radio" name="replicateDirection" id="${id}-replicateDirectionColumn" value="column"/>
                        <label class="form-check-label" for="${id}-replicateDirectionColumn">By Column</label>
                   </td>
               </tr>
               <tr class="--mle-showFillDirection">
                   <td>
                       <label class="form-label">Fill Direction:</label>
                   </td>
                   <td>
                        <input class="form-check-input" type="radio" name="fillDirection" id="${id}-fillDirectionRow" value="row"/>
                        <label class="form-check-label" for="${id}-fillDirectionRow">By Row</label>&nbsp;
                        <input class="form-check-input" type="radio" name="fillDirection" id="${id}-fillDirectionColumn" value="column"/>
                        <label class="form-check-label" for="${id}-fillDirectionColumn">By Column</label>
                   </td>
               </tr>
               <tr>
                   <td>&nbsp;</td>
                   <td>
                        <input class="form-check-input" type="checkbox" id="${id}-rectangularFill" name="rectangularFill"/>
                        <label class="form-check-label" for="${id}-rectangularFill">Rectangular Fill</label>
                   </td>
               </tr>
           </table></form>
        </div>
        <div class="modal-footer">
            <div>
            ${data.modal ? `
            <button class="btn btn-primary option" data-index="0">OK</button>
            <button class="btn btn-secondary option" data-index="1">Cancel</button>
            ` : `
            <button class="btn btn-secondary option" data-index="2">Close</button>
            `}
            </div>
            <div class="--mle-supplementary">
                    <input class="form-check-input" type="checkbox" id="${id}-displaySettingsOnNextFill" name="displaySettingsOnNextFill"/>
                    <label class="form-check-label" for="${id}-displaySettingsOnNextFill">Display these settings on next fill</label>
            </div>
        </div>
    </div>
</div>
    `
}
