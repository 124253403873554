
// TODO: Implement busy overlay

import {Utils, HistoryManager} from 'myassays-global';

const { repeat, range, isBetween, makeDataUrl } = Utils;

import MainState from './model/MainState';
import FillSettings from './model/FillSettings';
import Cell from './model/Cell';
import FillSettingsView from './views/FillSettingsView';
import styles from './myassays-layout-editor.css?raw';
import cursorErase from './media/icons.getbootstrap.com/eraser.cursor.svg?raw';
import cursorPaint from './media/icons.getbootstrap.com/paint-bucket.cursor.svg?raw';
import flagGraphic from './media/flag.svg?raw';
import cornerDragHandleGraphic from './media/handle-corner.svg?raw';
import AlertView from './views/AlertView.js';

const MODE_PAINT = 'paint';
const MODE_ERASE = 'erase';
const MODE_FLAGS = 'flags';
const NULL_SAMPLE_TYPE = 1;

function spaceIfZero(text) {
    return String(text) === '0' ? '&nbsp;' : text;
}

function drawSvgArrow(x1, y1, x2, y2) {
    return `
    <svg>
    <defs>
      <marker id='head' orient="auto"
        markerWidth='3' markerHeight='4'
        refX='0.1' refY='2'>
        <path d='M0,0 V4 L2,2 Z' fill="white"/>
      </marker>
    </defs>
    
    <line
      id='arrow-border'
      stroke-width='6'
      stroke='blue'  
      x1="${x1}"
      y1="${y1}"
      x2="${x2}"
      y2="${y2}"
      />

    <line
      id='arrow-line'
      marker-end='url(#head)'
      stroke-width='4'
      stroke='white'  
      x1="${x1}"
      y1="${y1}"
      x2="${x2}"
      y2="${y2}"
      />
    </svg> 
    `
}

export default class MyassaysLayoutEditor extends HTMLElement {
    static serialNo = 1;

    constructor(options) {
        super();

        if (options) {
            if (options.id !== undefined && typeof options.id !== 'string') {
                throw new Error('options.id should be a string');
            }
            if (options.config !== undefined) {
                if (typeof options.config !== 'object') {
                    throw new Error('options.config should be an object');
                }
            } else {
                throw new Error('options.config is missing');
            }
            if (options.flagMode !== undefined && typeof options.flagMode !== 'boolean') {
                throw new Error('options.flagMode should be boolean');
            }
            if (options.appendToElement !== undefined && !(options.appendToElement instanceof HTMLElement)) {
                throw new Error('options.appendToElement should be and HTML element');
            }
            if (options.validator !== undefined && typeof options.validator !== 'function') {
                throw new Error('options.validator should be a function');
            }
            this.options = options;
            this._id = options.id;
            this.flagMode = !!options.flagMode;
            this.config = this.options.config;
            this.validation = this.options.validation;
        }

        this._id = this._id || `layout-editor-${this.constructor.serialNo++}`;

        this.state = new MainState();
        this.addStateEventListeners();

        this.fillSettings = new FillSettings();
        this.addFillSettingsEventListeners();

        this.cells = [];

        this.startCell = null;
        this.endCell = null;
        this.numbersReplicated = 0;

        this.historyManager = new HistoryManager();
        this.historyManager.addEventListener(HistoryManager.EVENT_HISTORY_CHANGE, this.onHistoryChange);

        if (options) {
            const parentElem = options.appendToElement;
            if (parentElem) {
                parentElem.appendChild(this);
            }
        }
    }

    static get observedAttributes() {
        return ['data-config', 'data-flag-mode', 'data-validation'];
    }

    static template = (id, data) => `

<div class="modal fade myassays-layout-editor" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <style>

.--mle-plate.--mle-${MODE_ERASE} {
    cursor: url("${makeDataUrl(cursorErase)}") 9 28, pointer;
}

.--mle-plate.--mle-${MODE_FLAGS} {
    cursor: pointer;
}

.--mle-flag {
    background-image: url("${makeDataUrl(flagGraphic)}");
}
.--mle-corner-drag-handle {
    background-image: url("${makeDataUrl(cornerDragHandleGraphic)}");
}
        
${styles}

${data.sampleTypes.map(item => `
[data-sample-type="${item.Id}"] .--mle-circle {background-color: ${item.Colour.toLowerCase()}}
.--mle-plate.--mle-${MODE_PAINT}[data-selected-sample-type="${item.Id}"] {
    cursor: url("${makeDataUrl(cursorPaint.replace(/red/g, item.Colour.toLowerCase()))}") 26 20, pointer;
}
`).join(' ')}

</style>
 <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <div class="btn-toolbar">
            <div class="btn-group me-2">
              <button name="save-and-close" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-title="Save changes and close"><i class="bi-save"></i><span class="--mle-wide-only"> Save and Close</span></button>
            </div>
            <div class="btn-group me-2">
              <button name="clear" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-title="${data.flagMode ? 'Delete all flags' : 'Clear layout'}"><i class="bi-trash3"></i><span class="--mle-wide-only"> ${data.flagMode ? 'Delete Flags' : 'Clear'}</span></button>
            </div>
            <div class="btn-group me-2">
                <button name="undo" disabled class="btn btn-secondary --mle-btn-icon" data-bs-toggle="tooltip" data-bs-title="Undo last change"><i class="bi-arrow-counterclockwise"></i></button>
                <button name="redo" disabled class="btn btn-secondary --mle-btn-icon" data-bs-toggle="tooltip" data-bs-title="Redo last undone change"><i class="bi-arrow-clockwise"></i></button>             
            </div>
            ${data.flagMode ? '' : `
            <div class="btn-group me-2">
                <input type="radio" name="edit-mode" id="${id}-edit-mode-erase" class="btn-check" data-mode="${MODE_ERASE}"/>
                <label class="btn btn-secondary --mle-btn-icon" for="${id}-edit-mode-erase" data-bs-toggle="tooltip" data-bs-title="Select groups to remove"><i class="bi-eraser"></i></label>
                <input type="radio" name="edit-mode" id="${id}-edit-mode-paint" class="btn-check" data-mode="${MODE_PAINT}"/>
                <label class="btn btn-secondary --mle-btn-icon" for="${id}-edit-mode-paint" data-bs-toggle="tooltip" data-bs-title="Select positions to fill"><i class="bi-paint-bucket"></i></label>
            </div>
            <div class="btn-group me-2">
                <input type="checkbox" name="fill-settings" id="${id}-fill-settings" class="btn-check"/>
                <label class="btn btn-secondary" for="${id}-fill-settings" data-bs-toggle="tooltip" data-bs-title="Toggle fill settings popup"><i class="bi-sliders"></i><span class="--mle-wide-only"> Fill Settings...</span></label>
            </div>`}
        </div>
        <button type="button" class="btn-close --mle-btn-help" name="help" aria-label="Help" data-bs-toggle="tooltip" data-bs-title="Show layout editor help"></button>
        <button type="button" class="btn-close" name="close" aria-label="Close" data-bs-toggle="tooltip" data-bs-title="Exit without saving"></button>
      </div>
      <div class="modal-body">
        <div class="--mle-plate-wrapper">
            <div class="--mle-plate --mle-${data.state.mode}">
                <table>
                    <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            ${repeat(data.columns, col => `<td>${col + 1}</td>`)}
                        </tr>
                        ${repeat(data.rows, row => `<tr><td>${data.toRowCode(row + 1)}</td>${repeat(data.columns, col => {
    const cell = data.getCellAtRowColumn(row, col)
    return `<td class="--mle-cell" data-id="${cell.get('id')}" data-sample-type="${cell.get('sampleTypeId')}" data-value="${cell.get('number')}"><div class="--mle-circle">${spaceIfZero(cell.get('number'))}</div>${data.flagMode ? `<div class="--mle-flag"></div>` : ''}</td>`
})}</tr>`)}
                    </tbody>
                </table>
            </div>
            <div class="--mle-arrow"></div>
        </div>
      </div>
      <div class="modal-footer">
         <div class="btn-toolbar">
            ${data.flagMode ? `
                <div class="--mle-wide-only">${data.sampleTypes.map(item => `<span class="--mle-sample-type-label" data-sample-type="${item.Id}"><span class="--mle-circle"></span>${item.Name}</span>`).join('')}</div>
                <div class="btn-group btn-group-sm me-2 --mle-compact-only"><div class="dropdown">
                    <button class="btn btn-secondary btn-sm dropdown-toggle --mle-sample-type-dropdown-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">Sample Types Key</span></button>
                    <ul class="dropdown-menu">
                    ${data.sampleTypes.map(item => `<li data-sample-type="${item.Id}">
                    <span class="--mle-circle"></span>${item.Name}
                    </li>`).join('')}
                    </ul>
                </div></div>
            ` : `
                <div class="btn-group btn-group-sm me-2 --mle-wide-only">${data.sampleTypes.map(item => `
                    <input type="radio" name="sample-type" id="${id}-sample-type-${item.Id}" class="btn-check" data-sample-type="${item.Id}"/>
                    <label class="btn btn-secondary" for="${id}-sample-type-${item.Id}" data-sample-type="${item.Id}"><span class="--mle-circle"></span>${item.Name}</label>
                `).join('')}</div>
                <div class="btn-group btn-group-sm me-2 --mle-compact-only"><div class="dropdown">
                    <button class="btn btn-secondary btn-sm dropdown-toggle --mle-sample-type-dropdown-button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-sample-type="${data.sampleTypes[0].Id}"><span class="--mle-circle"></span><span class="--mle-sample-type-dropdown-text">${data.sampleTypes[0].Name}</span></button>
                    <ul class="dropdown-menu">
                    ${data.sampleTypes.map(item => `<li>
                        <input type="radio" name="sample-type-dropdown" id="${id}-sample-type-${item.Id}" class="btn-check" data-sample-type="${item.Id}"/>
                        <label class="btn btn-sm" for="${id}-sample-type-${item.Id}" data-sample-type="${item.Id}"><span class="--mle-circle"></span>${item.Name}</label>
                    </li>`).join('')}
                    </ul>
                </div></div>
                <div class="input-group input-group-sm --mle-group-number-group">
                    <label class="input-group-text" for="${id}-group-number" data-bs-toggle="tooltip" data-bs-title="Double-click to reset">Group</label>
                    <input class="form-control" id="${id}-group-number" name="group-number" type="number" min="1" max="${data.cells.length}" value="${data.groupNumber}" aria-describedby="group-number"/>
                </div>
            `}
         </div>
       <div class="--mle-corner-drag-handle">&nbsp;</div>
    </div>
  </div>
  <div class="--mle-fill-settings"></div>
  <div class="--mle-validation-message"></div>
</div>
    `

    invalidate() {
        if (!this._invalidateTimeoutId) {
            this._invalidateTimeoutId = setTimeout(() => {
                this.configure();
                delete this._invalidateTimeoutId;
            });
        }
    }

    configure(config, flagMode = false, validation = undefined) {
        this.style.display = 'none';
        this.state.reset();
        this.fillSettings.reset();
        this.cells = [];

        if (config) {
            this.config = config;
            this.flagMode = flagMode;
            this.validation = validation;
        } else if (!this.options) {
            this.config = JSON.parse(this.getAttribute('data-config'));
            this.flagMode = this.getAttribute('data-flag-mode') === 'true';
            const validationName = this.getAttribute('data-validation');
            if (validationName) {
                this.validationName = validationName;
                this.validation = window[validationName];
                if (this.validation && (typeof this.validation !== 'function')) {
                    throw new Error('the "data-validator" attribute should be the name of a function in the "window" namespace');
                }
            } else {
                this.validation = undefined;
            }
        }

        if (! this.config) return;

        this.columns = parseInt(this.config.Width);
        this.rows = parseInt(this.config.Height);

        const defaultData = this.config.Default.split(',').map(item => Number(item));
        let id = 1;
        for (let row = 0; row < this.rows; row++) {
            for (let column = 0; column < this.columns; column++) {
                const cell = new Cell({
                    id: id++,
                    sampleTypeId: defaultData.shift(),
                    number: defaultData.shift(),
                });
                this.cells.push(cell);
                cell.addEventListener('change', this.onCellChange);
            }
        }

        const data = {
            columns: this.columns,
            rows: this.rows,
            cells: this.cells,
            sampleTypes: this.config.SampleTypes,
            toRowCode: row => row > 26 ? String.fromCharCode(64 + Math.floor(row / 26)) + String.fromCharCode(64 + row % 26) : String.fromCharCode(64 + row),
            state: this.state.attributes,
            getCellAtRowColumn: this.getCellAtRowColumn,
            groupNumber: this.fillSettings.get('groupNumber'),
            flagMode: this.flagMode,
        }
        const div = document.createElement('div');
        div.innerHTML = this.constructor.template(this._id, data);
        this._editor = div.firstElementChild;

        this.state.set({
            mode: this.flagMode ? MODE_FLAGS : MODE_ERASE,
            selectedSampleType: this.config.SampleTypes[this.config.SampleTypes.length - 1].Id,
        });

        this.addControlEventListeners();
        this.setGroupNumberAndVisibility();

        this._modal = new bootstrap.Modal(this._editor, {});
        this._editor.addEventListener('shown.bs.modal', () => {
            const modalContent = this._editor.querySelector('.modal-content');
            const modalHeader = this._editor.querySelector('.modal-header');
            const modalFooter = this._editor.querySelector('.modal-footer');
            modalContent.style.width = '10px';
            this._headerCompactMaxWidth = modalHeader.scrollWidth + 32;
            this._footerCompactMaxWidth = modalFooter.scrollWidth + 42;
            modalHeader.classList.toggle('--mle-compact', true);
            this._minModalWidth = modalHeader.scrollWidth + 32;
            modalHeader.classList.toggle('--mle-compact', false);
            modalContent.style.removeProperty('width');
        });
        this._modal.show();
        this.calcCellSize(750);
    }

    connectedCallback() {
        this.invalidate();
    }

    disconnectedCallback() {
        delete this.config;
        delete this.validation;
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (!this._editor) {
            this.invalidate();
        }
    }

    calcCellSize(pixelWidth) {
        this.cellSize = (pixelWidth - 32) / (this.columns + 1);
        this._editor.style.setProperty('--mle-cell-size', this.cellSize + 'px')
    }

    addStateEventListeners = () => {
        const getBySelector = (selector, doIfFound) => {
            const elem = this._editor.querySelector(selector);
            elem && doIfFound(elem);
        }
        const getByName = (name, doIfFound) => {
            getBySelector(`[name="${name}"]`, doIfFound);
        }

        this.state.addEventListener('change:canSave', evt => {
            getByName('save-and-close', elem => elem.toggleAttribute('disabled', !evt.data.newValue));
        });
        this.state.addEventListener('change:canClear', evt => {
            getByName('clear', elem => elem.toggleAttribute('disabled', !evt.data.newValue));
        });
        this.state.addEventListener('change:canUndo', evt => {
            getByName('undo', elem => elem.toggleAttribute('disabled', !evt.data.newValue));
        });
        this.state.addEventListener('change:canRedo', evt => {
            getByName('redo', elem => elem.toggleAttribute('disabled', !evt.data.newValue));
        });
        this.state.addEventListener('change:mode', evt => {
            if (evt.data.newValue !== MODE_FLAGS) {
                getBySelector(`[name="edit-mode"][data-mode="${evt.data.newValue}"]`, elem => {
                    elem.checked = true;
                });
                getByName('fill-settings', elem => {
                    elem.parentElement.classList.toggle('--mle-invisible', evt.data.newValue !== MODE_PAINT);
                });
            }
            if (evt.data.newValue === MODE_ERASE) {
                getBySelector('[name="sample-type"]:checked', elem => elem.checked = false);
                if (this._fillSettings) {
                    this.hideFillSettings();
                    getByName('fill-settings', elem => {
                        elem.checked = false;
                    });
                }
            } else if (evt.data.newValue === MODE_PAINT) {
                let sampleType = this.state.get('selectedSampleType');
                if (sampleType === NULL_SAMPLE_TYPE && this.layoutIsClear) {
                    sampleType = Number(this.config.SampleTypes.find(item => Number(item.Id) !== NULL_SAMPLE_TYPE).Id);
                    this.state.set({selectedSampleType: sampleType});
                } else {
                    getBySelector(`[name="sample-type"][data-sample-type="${sampleType}"]`, elem => {
                        elem.checked = true;
                    });
                    getBySelector(`[name="sample-type-dropdown"][data-sample-type="${sampleType}"]`, elem => {
                        elem.checked = true;
                    });
                }
            }
            getBySelector('.--mle-plate', elem => {
                elem.classList.toggle(`--mle-${MODE_ERASE}`, evt.data.newValue === MODE_ERASE);
                elem.classList.toggle(`--mle-${MODE_PAINT}`, evt.data.newValue === MODE_PAINT);
                elem.classList.toggle(`--mle-${MODE_FLAGS}`, evt.data.newValue === MODE_FLAGS);
            })
            this.setGroupNumberAndVisibility();
        });
        this.state.addEventListener('change:selectedSampleType', evt => {
            getBySelector(`[name="sample-type"][data-sample-type="${evt.data.newValue}"]`, elem => elem.checked = this.state.get('mode') === MODE_PAINT);
            getBySelector(`[name="sample-type-dropdown"][data-sample-type="${evt.data.newValue}"]`, elem => elem.checked = this.state.get('mode') === MODE_PAINT);
            getBySelector('.--mle-sample-type-dropdown-button', elem => elem.setAttribute('data-sample-type', evt.data.newValue));
            getBySelector('.--mle-sample-type-dropdown-text', elem => elem.innerHTML = this.config.SampleTypes.find(item => item.Id === evt.data.newValue).Name);
            getBySelector('.--mle-plate', elem => elem.setAttribute('data-selected-sample-type', evt.data.newValue));
            this.setGroupNumberAndVisibility();
            this.updateFillSettingsDialog();
        });
        this.state.addEventListener('change:busy', this.onBusyChange);
    }

    addFillSettingsEventListeners() {
        this.fillSettings.addEventListener('change:groupNumber', evt => {
            this.flagMode || (this._editor.querySelector('[name="group-number"]').value = evt.data.newValue);
        });
    }

    setGroupNumberAndVisibility() {
        const paintMode = this.state.get('mode') === MODE_PAINT;
        const sampleTypeId = this.state.get('selectedSampleType');
        let groupNumber = 0;
        if (sampleTypeId !== null && sampleTypeId !== 1) {
            this.cells.filter(cell => (cell.get('sampleTypeId') === sampleTypeId)).forEach(cell => {
                groupNumber = Math.max(groupNumber, cell.get('number'));
            });
            groupNumber++;
        }
        this.fillSettings.set({groupNumber: groupNumber});
        this._editor.classList.toggle('--mle-hide-group-number', !paintMode || sampleTypeId === null || sampleTypeId === 1);
    }

    addControlEventListeners() {
        this._editor.querySelector('[name="save-and-close"]').addEventListener('click', this.onSaveAndCloseClick);
        this._editor.querySelector('[name="clear"]').addEventListener('click', this.onClearClick);
        this._editor.querySelector('[name="undo"]').addEventListener('click', this.onUndoClick);
        this._editor.querySelector('[name="redo"]').addEventListener('click', this.onRedoClick);
        Array.from(this._editor.querySelectorAll('[name="edit-mode"]')).forEach(item => item.addEventListener('click', this.onEditModeClick));
        this.flagMode || this._editor.querySelector('[name="fill-settings"]').addEventListener('click', this.onFillSettingsClick);
        Array.from(this._editor.querySelectorAll('[name="sample-type"]')).forEach(item => item.addEventListener('click', this.onSampleTypeClick));

        if (! this.flagMode) {
            const groupNumberInput = this._editor.querySelector('[name="group-number"]');
            groupNumberInput.addEventListener('change', this.onGroupNumberChange);
            this._editor.querySelector(`label[for="${groupNumberInput.id}"]`).addEventListener('dblclick', this.onGroupNumberDoubleClick)
        }

        this._editor.querySelector('[name="close"]').addEventListener('click', this.onCloseClick);
        this._editor.querySelector('[name="help"]').addEventListener('click', this.onHelpClick);

        const tooltipTriggerList = this._editor.querySelectorAll('[data-bs-toggle="tooltip"]');
        this._tooltipList = [...tooltipTriggerList].map(this.createTooltip);

        Utils.makeDraggable(this._editor.querySelector('.--mle-plate'))
            .onStart(this.dragStart)
            .onMove(this.dragMove)
            .onEnd(this.dragEnd)
            .onException(this.dragEnd);

        Utils.makeMovable(this._editor.querySelector('.modal-content'), this._editor.querySelector('.modal-header')).onStart(() => this.hideTooltips());

        Utils.makeDraggable(this._editor.querySelector('.--mle-corner-drag-handle'))
            .onStart(this.resizeStart);
    }

    createTooltip = element => {
        const tooltip = new bootstrap.Tooltip(element, {
            trigger: 'hover',
            delay: {
                show: 750,
                hide: 100,
            },
        });
        element.addEventListener('mouseout', evt => tooltip.hide());
        return tooltip;
    }

    hideTooltips() {
        this._tooltipList.forEach(tooltip => tooltip.hide());
    }

    resizeStart = (evt, positionInfo, controller) => {
        evt.preventDefault();
        const modalContent = this._editor.querySelector('.modal-content');
        const header = modalContent.querySelector('.modal-header');
        const footer = modalContent.querySelector('.modal-footer');
        modalContent.style.top = modalContent.offsetTop + 'px';
        modalContent.style.left = modalContent.offsetLeft + 'px';
        modalContent.style.position = 'absolute';

        const startX = positionInfo.pageX;
        const startWidth = modalContent.offsetWidth;

        let headerCompact = header.classList.contains('--mle-compact');
        let footerCompact = footer.classList.contains('--mle-compact');
        let currentWidth = startWidth;
        let newWidth = currentWidth;

        const resize = () => {
            currentWidth = newWidth;
            modalContent.style.width = newWidth + 'px';
            this.calcCellSize(newWidth);
            const newHeaderCompact = newWidth <= this._headerCompactMaxWidth;
            const newFooterCompact = newWidth <= this._footerCompactMaxWidth;
            if (newHeaderCompact !== headerCompact) {
                header.classList.toggle('--mle-compact', newHeaderCompact);
                headerCompact = newHeaderCompact;
            }
            if (newFooterCompact !== footerCompact) {
                footer.classList.toggle('--mle-compact', newFooterCompact);
                footerCompact = newFooterCompact;
            }
        }

        const onMove = (evt, positionInfo) => {
            evt.preventDefault();
            newWidth = Math.max(startWidth - startX + positionInfo.pageX, this._minModalWidth);
            if (newWidth !== currentWidth) {
                if (this._resizeTimeoutId === undefined) {
                    this._resizeTimeoutId = setTimeout(() => {
                        resize();
                        delete this._resizeTimeoutId;
                    }, 20);
                }
            }
        };

        controller
            .onMove(onMove)
            .onEnd(resize)
            .onException(resize);
    }

    onSaveAndCloseClick = evt => {
        this.saveAndClose();
    }

    onClearClick = evt => {
        this.flagMode ? this.clearFlags() : this.clear();
    }

    onUndoClick = evt => {
        this.undo();
    }

    onRedoClick = evt => {
        this.redo();
    }

    onEditModeClick = evt => {
        const elem = evt.currentTarget;
        this.state.set({
            mode: elem.getAttribute('data-mode'),
        });
    }

    onFillSettingsClick = evt => {
        if (evt.currentTarget.checked) {
            this.showFillSettings(option => {
                this._editor.querySelector('[name="fill-settings"]').checked = false;
            }, false);
        } else {
            this.hideFillSettings();
        }
    }

    get fillSettingsShowing() {
        return this._editor.querySelector('[name="fill-settings"]').checked;
    }

    onHelpClick = evt => {
        this.showHelp();
    }

    onCloseClick = evt => {
        this.close();
    }

    onGroupNumberChange = evt => {
        const input = evt.currentTarget;
        if (input.value < 1) input.value = 1;
        const maxGroupNumber = this.columns * this.rows;
        if (input.value > maxGroupNumber) input.value = maxGroupNumber;
        this.fillSettings.set({
            groupNumber: parseInt(input.value)
        });
    }

    onGroupNumberDoubleClick = evt => {
        this.fillSettings.set({
            groupNumber: 1,
        });
    }

    onSampleTypeClick = evt => {
        const button = evt.currentTarget;
        this.state.set({
            selectedSampleType: parseInt(button.getAttribute('data-sample-type')),
            mode: MODE_PAINT,
        });
    }


    toggleClassByName(elementName, className, truth) {
        const elem = this._editor.querySelector(`[name="${elementName}"]`);
        elem && elem.classList.toggle(className, truth);
    }

    toggleAttributeByName(elementName, attributeName, truth) {
        const elem = this._editor.querySelector(`[name="${elementName}"]`);
        elem && elem.toggleAttribute(attributeName, truth);
    }

    toggleClassBySelector(selector, className, truth) {
        const elem = this._editor.querySelector(selector);
        elem && elem.classList.toggle(className, truth);
    }

    getCellAtRowColumn(row, column) {
        return this.cells[row * this.columns + column];
    }

    getCellById(id) {
        return this.cells[id - 1];
    }

    getCellRowColumn(cell) {
        if (cell) {
            const cellIndex = cell.get('id') - 1;
            const row = Math.floor(cellIndex / this.columns);
            const column = cellIndex - (row * this.columns);
            return {row, column};
        } else {
            return null;
        }
    }

    close() {
        const evt = new CustomEvent('closelayouteditor', {bubbles: true});
        this.dispatchEvent(evt);
        this._removeModal();
    }

    _removeModal() {
        this._editor.addEventListener('hidden.bs.modal', evt => {
            this._editor.remove();
            delete this._editor;
            this._modal.dispose();
            delete this._modal;
        });
        this._modal.hide();
        this._cleanUp();

    }

    saveAndClose() {
        const data = this.getData();
        const doIt = () => {
            const evt = new CustomEvent('saveandclose', {bubbles: true, detail: data});
            this.dispatchEvent(evt);
            this._removeModal();
        }
        if (!!this.validationName && !this.validation) {
            this.validation = window[this.validationName];
            if (typeof this.validation !== 'function') {
                throw new Error('the "data-validator" attribute should be the name of a function in the "window" namespace');
            }
        }
        if (!!this.validation) {
            this.validation(JSON.stringify(this.config), data).then(errors => {
                if (errors && errors.length) {
                    new AlertView(
                        this._editor.querySelector('.--mle-validation-message'),
                        'Validation Errors',
                        `<p>The layout does not meet the requirements of this assay, specifically:</p><ul>${errors.map(error => `<li>${error}</li>`).join('')}</ul><p>Please update the layout.</p>`,
                        () => this.hideTooltips(),
                    );
                } else {
                    doIt();
                }
            });
        } else {
            doIt();
        }
    }

    _cleanUp() {
        if (this.options && this.options.appendToElement) {
            this.remove();
        } else {
            this.removeAttribute('data-config');
        }
    }

    showFillSettings(callBack, modal = true) {
        const modalContent = this._editor.querySelector('.modal-content');
        const rect = modalContent.getBoundingClientRect();
        const left = rect.left + rect.width;
        this._fillSettings = new FillSettingsView(this._editor.querySelector('.--mle-fill-settings'), this.fillSettings, {
            isNullSampleType: this.state.get('selectedSampleType') === NULL_SAMPLE_TYPE,
            startRowCol: this.getCellRowColumn(this.startCell),
            endRowCol: this.getCellRowColumn(this.endCell),
            cellsLength: this.cells.length,
            callBack: callBack,
            modal: modal,
            left: left,
            idPrefix: this._id,
            onDragStart: () => this.hideTooltips(),
        });
    }

    updateFillSettingsDialog() {
        if (this._fillSettings) {
            this._fillSettings.updateOptions({
                isNullSampleType: this.state.get('selectedSampleType') === NULL_SAMPLE_TYPE,
            });
        }
    }

    hideFillSettings() {
        this._fillSettings && this._fillSettings.close();
        delete this._fillSettings;
    }

    showHelp() {
        const evt = new CustomEvent('help', {bubbles: true});
        this.dispatchEvent(evt);
    }

    getData() {
        if (this.flagMode) {
            return this.cells.filter(item => item.get('flagged')).map(item => item.get('id'));
        } else {
            return this.cells.map(item => item.get('sampleTypeId') + ',' + item.get('number')).join(',');
        }
    }

    setConfig(config, flagMode = false, validation = undefined) {
        if (typeof config !== 'object') {
            throw new Error('config should be an object');
        }
        if (typeof flagMode !== 'boolean') {
            throw new Error('flagMode should be a boolean');
        }
        if (!!validation && (typeof validation !== 'function')) {
            throw new Error('validation should be a function');
        }
        this.configure(config, flagMode, validation);
    }


    setBusy(busy) {
        this.state.set({busy});
    }

    onBusyChange() {

    }

    onCellClick = evt => {
        const cellElement = evt.currentTarget;
        const cell = this.getCellById(cellElement.getAttribute('data-id'));
        this.toggleCellFlagged(cell);
    }

    dragStart = (evt, positionInfo) => {
        evt.preventDefault();
        const cellElement = document.elementFromPoint(positionInfo.clientX, positionInfo.clientY);
        if (cellElement.classList.contains('--mle-cell')) {
            const cell = this.getCellById(cellElement.getAttribute('data-id'));
            if (this.startCell) return; // on windows an extra mousedown event is triggered

            this.historyManager.startTransaction();
            this.startCell = this.endCell = cell;
            if (this.state.get('mode') === MODE_ERASE) {
                this.eraseCell(cell);
            } else {
                this.onCellSelectionChange();
            }
            this.currentRowCol = this.getCellRowColumn(cell);
        }
    }

    dragMove = (evt, positionInfo) => {
        evt.preventDefault();
        const cellElement = document.elementFromPoint(positionInfo.clientX, positionInfo.clientY);
        if (cellElement.classList.contains('--mle-cell')) {
            const cell = this.getCellById(cellElement.getAttribute('data-id'));
            this.endCell = cell;
            if (this.state.get('mode') === MODE_ERASE) {
                this.eraseCell(cell);
            } else {
                this.onCellSelectionChange();
            }
        }
    }

    dragEnd = (evt) => {
        evt.preventDefault();
        let groupNumber;
        const autoIncrement = this.fillSettings.get('autoIncrement');
        if (this.state.get('mode') === MODE_PAINT) {
            groupNumber = this.fillCellRange();
            this.historyManager.endTransaction();
            if (this.startCell !== this.endCell && this.fillSettings.get('displaySettingsOnNextFill') && !this.fillSettingsShowing) {
                const onSettingsChange = () => {
                    this.undo();
                    this.historyManager.startTransaction();
                    groupNumber = this.fillCellRange();
                    this.historyManager.endTransaction();
                };
                this.fillSettings.addEventListener('change', onSettingsChange);
                this.state.addEventListener('change:selectedSampleType', onSettingsChange);
                this.enableBottomToolbarOnly(true);
                this.showFillSettings(option => {
                    this.fillSettings.removeEventListener('change', onSettingsChange);
                    this.state.removeEventListener('change:selectedSampleType', onSettingsChange);
                    this.enableBottomToolbarOnly(false);
                    this.startCell = this.endCell = null;
                    switch (option) {
                        case 0:
                            if (autoIncrement) {
                                this.fillSettings.set({
                                    groupNumber: groupNumber
                                });
                            }
                            break;
                        case 1:
                            this.undo();
                            break;
                    }
                });
            } else {
                if (autoIncrement) {
                    this.fillSettings.set({
                        groupNumber: groupNumber
                    });
                }
                this.startCell = this.endCell = null;
            }
            this.removeArrow();
        } else {
            this.historyManager.endTransaction();
            this.startCell = this.endCell = null;
        }
    }

    onHistoryChange = () => {
        this.state.set({
            canUndo: this.historyManager.canUndo(),
            canRedo: this.historyManager.canRedo(),
            canSave: !this.isAllUnused(),
        });
    }

    isAllUnused() {
        const unusedCells = this.cells.filter(item => item.get('sampleTypeId') === NULL_SAMPLE_TYPE);
        return unusedCells.length === this.cells.length;
    }

    onCellChange = evt => {
        const cell = evt.currentTarget;
        const before = cell.previousAttributes;
        before.selected = false;
        const after = cell.attributes;
        const cellElem = this._editor.querySelector(`.--mle-cell[data-id="${after.id}"]`);
        cellElem.setAttribute('data-sample-type', after.sampleTypeId);
        cellElem.setAttribute('data-value', after.number);
        cellElem.classList.toggle('--mle-selected', after.selected);
        cellElem.classList.toggle('--mle-flagged', after.flagged);
        cellElem.querySelector('.--mle-circle').innerHTML = spaceIfZero(after.number);

        after.selected = false;
        this.historyManager.addChange(before, after);

    }

    undo() {
        const changes = this.historyManager.undo() || [];
        changes.forEach(change => {
            const cell = this.getCellById(change.before.id);
            cell.set(change.before);
        });
    }

    redo() {
        const changes = this.historyManager.redo() || [];
        changes.forEach(change => {
            const cell = this.getCellById(change.after.id);
            cell.set(change.after);
        });
    }

    clear() {
        this.historyManager.startTransaction();
        this.cells.forEach(cell => {
            cell.set({
                sampleTypeId: NULL_SAMPLE_TYPE,
                number: 0
            });
        });
        this.historyManager.endTransaction();
        this.numbersReplicated = 0;
        this.fillSettings.set({
            groupNumber: 1
        });
        this.state.set({
            mode: MODE_PAINT
        });
    }

    get layoutIsClear() {
        return this.cells.filter(cell => cell.get('sampleTypeId') !== NULL_SAMPLE_TYPE).length === 0;
    }

    clearFlags() {
        const flaggedCells = this.cells.filter(cell => cell.get("flagged"));

        if (flaggedCells.length) {
            this.historyManager.startTransaction();
            flaggedCells.forEach(cell => cell.set({flagged: false}));
            this.historyManager.endTransaction();
        }
    }

    toggleCellFlagged(cell) {
        this.historyManager.startTransaction();
        const previousFlagged = cell.get("flagged");
        cell.set({
            flagged: !previousFlagged,
        });
        this.historyManager.endTransaction()
    }

    eraseCell(cell) {
        const previousTypeId = cell.get("sampleTypeId");
        if (previousTypeId !== NULL_SAMPLE_TYPE) {
            const previousNumber = cell.get("number");
            cell.set({
                sampleTypeId: NULL_SAMPLE_TYPE,
                number: 0,
            });
            this.cells.forEach(otherCell => {
                if (otherCell.get("sampleTypeId") === previousTypeId) {
                    const number = otherCell.get("number");
                    if (number === previousNumber) {
                        otherCell.set({
                            sampleTypeId: NULL_SAMPLE_TYPE,
                            number: 0,
                        });
                    } else if (number > previousNumber) {
                        otherCell.set({
                            number: number - 1,
                        });
                    }
                }
            });
        }
    }

    removeArrow() {
        if (this.arrowElem)  {
            this.arrowElem.innerHTML = '';
            delete this.arrowElem;
        }
    }

    onCellSelectionChange = () => {
        if (this.fillSettings.get("displaySettingsOnNextFill") && !this.fillSettingsShowing) {
            this.cells.forEach(cell => {
                cell.set({
                    selected: (cell === this.startCell || cell === this.endCell),
                });
            });
            if (this.startCell !== this.endCell) {
                if (!this.arrowElem) {
                    this.arrowElem = this._editor.querySelector(".--mle-arrow");
                }

                const cellRadius = this.cellSize / 2;
                const startCellElem = this._editor.querySelector(`.--mle-cell[data-id="${this.startCell.get('id')}"]`);
                const endCellElem = this._editor.querySelector(`.--mle-cell[data-id="${this.endCell.get('id')}"]`);

                this.arrowElem.innerHTML = drawSvgArrow(
                    startCellElem.offsetLeft + cellRadius,
                    startCellElem.offsetTop + cellRadius,
                    endCellElem.offsetLeft + cellRadius,
                    endCellElem.offsetTop + cellRadius
                )
            } else {
                this.removeArrow();
            }
        } else {
            const startCoords = this.getCellRowColumn(this.startCell);
            const endCoords = this.getCellRowColumn(this.endCell);
            this.cells.forEach(cell => {
                const cellCoords = this.getCellRowColumn(cell);
                let selected = false;
                if (this.fillSettings.get('rectangularFill')) {
                    selected = isBetween(cellCoords.row, startCoords.row, endCoords.row)
                        && isBetween(cellCoords.column, startCoords.column, endCoords.column);
                } else if (this.fillSettings.get('fillDirection') === 'row') {
                    selected = isBetween(cell.get('id'), this.startCell.get('id'), this.endCell.get("id"));
                } else {
                    if (startCoords.column === endCoords.column) {
                        selected = cellCoords.column === startCoords.column && isBetween(cellCoords.row, startCoords.row, endCoords.row)
                    } else if (cellCoords.column === startCoords.column) {
                        selected = startCoords.column < endCoords.column ? cellCoords.row >= startCoords.row : cellCoords.row <= startCoords.row;
                    } else if (cellCoords.column === endCoords.column) {
                        selected = startCoords.column < endCoords.column ? cellCoords.row <= endCoords.row : cellCoords.row >= endCoords.row;
                    } else {
                        selected = isBetween(cellCoords.column, startCoords.column, endCoords.column);
                    }
                }
                cell.set({selected});
            });
        }
    }

    fillCellRange() {
        let i, start, end, rowRange, columnRange, replicateRange, n;
        const sampleTypeId = this.state.get('selectedSampleType');
        let {
            groupNumber,
            autoIncrement,
            replicates,
            rectangularFill,
            fillDirection,
            replicateDirection
        } = this.fillSettings.attributes;

        const startCoords = this.getCellRowColumn(this.startCell);
        const endCoords = this.getCellRowColumn(this.endCell);

        const getGroupNumber = () => {
            const returnValue = groupNumber;
            if (autoIncrement) {
                this.numbersReplicated++;
                if (this.numbersReplicated >= replicates) {
                    groupNumber++;
                    this.numbersReplicated = 0;
                }
            }
            return returnValue;
        };

        const finishGroup = () => {
            if (this.numbersReplicated > 0) {
                groupNumber++;
                this.numbersReplicated = 0;
            }
        };

        const getRange = (start, end, step) => {
            step = step || 1;
            if (start > end) {
                end--;
            } else {
                end++;
            }
            return range(start, end, start > end ? -step : step);
        };

        const setCell = (cell, number) => {
            const setData = {
                selected: false,
                sampleTypeId: sampleTypeId,
                number: sampleTypeId === NULL_SAMPLE_TYPE ? 0 : number
            };
            cell.set(setData);
        };

        const setCellByRowColumn = (row, column, number) => {
            const cell = this.getCellById(row * this.columns + column + 1);
            setCell(cell, number);
        };

        const getAlternativeCellSequenceNumber = cell => {
            const rowCol = this.getCellRowColumn(cell);
            return rowCol.row + rowCol.column * this.rows + 1;
        };

        const getCellByAlternativeSequenceNumber = number => {
            const rows = this.rows;
            const columns = this.columns;
            const column = Math.floor((number - 1) / rows);
            const row = (number - 1) - rows * column;
            const id = column + row * columns + 1;
            return this.getCellById(id);
        };

        if (this.startCell === this.endCell) {
            setCell(this.startCell, getGroupNumber());
        } else {
            this.numbersReplicated = 0;
            if (rectangularFill) {
                // create a lookup table for numbering.
                switch (true) {
                    case fillDirection === 'row' && replicateDirection === 'row':
                        rowRange = getRange(startCoords.row, endCoords.row);
                        columnRange = getRange(startCoords.column, endCoords.column);
                        rowRange.forEach(row => {
                            columnRange.forEach(column => {
                                setCellByRowColumn(row, column, getGroupNumber());
                            });
                            finishGroup();
                        });
                        break;
                    case fillDirection === 'row' && replicateDirection === 'column':
                        rowRange = getRange(startCoords.row, endCoords.row, replicates);
                        columnRange = getRange(startCoords.column, endCoords.column);
                        replicateRange = getRange(0, replicates - 1);
                        rowRange.forEach(row => {
                            columnRange.forEach(column => {
                                replicateRange.forEach(replicate => {
                                    n = row + replicate;
                                    if (!isBetween(n, startCoords.row, endCoords.row)) {
                                        finishGroup();
                                    } else {
                                        setCellByRowColumn(n, column, getGroupNumber());
                                    }
                                });
                            });
                        });
                        break;
                    case fillDirection === 'column' && replicateDirection === 'row':
                        rowRange = getRange(startCoords.row, endCoords.row);
                        columnRange = getRange(startCoords.column, endCoords.column, replicates);
                        replicateRange = getRange(0, replicates - 1);
                        columnRange.forEach(column => {
                            rowRange.forEach(row => {
                                replicateRange.forEach(replicate => {
                                    n = column + replicate;
                                    if (!isBetween(n, startCoords.column, endCoords.column)) {
                                        finishGroup();
                                    } else {
                                        setCellByRowColumn(row, n, getGroupNumber());
                                    }
                                });
                            });
                        });
                        break;
                    case fillDirection === 'column' && replicateDirection === 'column':
                        rowRange = getRange(startCoords.row, endCoords.row);
                        columnRange = getRange(startCoords.column, endCoords.column);
                        columnRange.forEach(column => {
                            rowRange.forEach(row => {
                                setCellByRowColumn(row, column, getGroupNumber());
                            });
                            finishGroup();
                        });
                        break;
                }
            } else {
                switch (fillDirection) {
                    case 'row':
                        start = this.startCell.get('id');
                        end = this.endCell.get('id');
                        if (start < end) {
                            for (i = start; i <= end; i++) {
                                setCell(this.getCellById(i), getGroupNumber());
                            }
                        } else {
                            for (i = start; i >= end; i--) {
                                setCell(this.getCellById(i), getGroupNumber());
                            }
                        }
                        break;
                    case 'column':
                        start = getAlternativeCellSequenceNumber(this.startCell);
                        end = getAlternativeCellSequenceNumber(this.endCell);
                        if (start < end) {
                            for (i = start; i <= end; i++) {
                                setCell(getCellByAlternativeSequenceNumber(i), getGroupNumber());
                            }
                        } else {
                            for (i = start; i >= end; i--) {
                                setCell(getCellByAlternativeSequenceNumber(i), getGroupNumber());
                            }
                        }
                        break;
                }
            }
        }

        return groupNumber;
    }

    enableBottomToolbarOnly(trueFalse) {
        this._editor.classList.toggle("enable-bottom-toolbar-only", trueFalse);
    }

}

