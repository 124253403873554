import {Utils} from 'myassays-global';

export default class AlertView extends EventTarget {
    constructor(rootElement, titleText, messageText, onDragStart = () => {}) {
        super();

        this._onDragStart = onDragStart;
        this.root = rootElement;
        this.root.innerHTML = AlertView.template({
            titleText,
            messageText,
        });

        const popupElem = this.root.querySelector('.--mle-alertView');
        popupElem.style.top = (this.root.offsetHeight/2 - popupElem.offsetHeight/2) + 'px';
        popupElem.style.left = (this.root.offsetWidth/2 - popupElem.offsetWidth/2) + 'px';
        this.addControlEventListeners();
    }

    close() {
        this.root.innerHTML = '';
    }

    addControlEventListeners() {
        this.root.querySelectorAll('button.option').forEach(elem => elem.addEventListener('click', this.onOkClick));

        Utils.makeMovable(this.root.querySelector('.--mle-alertView'), this.root.querySelector('.--mle-dragHandle')).onStart(() => this._onDragStart());

        this.root.querySelector('.--mle-modalMask').addEventListener('click', evt => evt.preventDefault());
    }

    onOkClick = () => {
        this.close();
    }

    static template = data => `
<div class="--mle-modalMask"> </div>
<div class="modal modal-sm" style="display: block">
    <div class="--mle-alertView modal-dialog">
    <div class="modal-content">
        <div class="modal-header --mle-dragHandle"><h5>${data.titleText}</h5></div>
        <div class="modal-body">${data.messageText}</div>
        <div class="modal-footer">
            <button class="btn btn-primary option" data-index="0">OK</button>
        </div>
    </div>
</div>
    `
}
