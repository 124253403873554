import { Model } from 'myassays-global';

export default class MainState extends Model {
    static defaults = {
        canSave: true,
        canClear: true,
        canUndo: false,
        canRedo: false,
        mode: '',
        selectedSampleType: null,
        flagMode: false,
        busy: false,
    };
}
